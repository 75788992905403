<template>
  <div class="container">
    <img src="/img/paladi.png" class="welcome__logo" />
    <form @submit.prevent="confirmCode()" class="personal-form">
      <div class="personal-form__item">
        <div class="personal-form__label">
          Введите код подтверждения
        </div>
        <FormText :pattern="'#####'" v-model="code"/>
      </div>
      <div class="personal-form__item">
        <button class="btn personal-form__submit">
          Подтвердить код
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";

export default {
  name: "ConfirmCode",

  components: {
    FormText,
  },

  data() {
    return {
      code: null,
    }
  },

  methods: {
    confirmCode() {
      this.$store
        .dispatch("PASSWORD_RESTORE", {
          code: this.code
        })
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", error);
        });
    }
  }
}
</script>
